import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { backArrow } from '../../Image/Index';
import { merchantSchema, transaction, transactionRedeem } from '../../services/services';
import { alertModal, getDate } from '../../utils/helper';
import Loader from '../partial/Loader';
import swal from "sweetalert";

function RecieptResponse() {
    const location = useLocation();
    const navigate = useNavigate(); 
    const [loader, setLoader] = useState(false);
    const response = location?.state?.data?.data;
    const email = location?.state?.email;

//  useEffect(() => {
//         const fetch = async () => {
//             const token = JSON.parse(localStorage.getItem("token_gen"));
//             const id = localStorage.getItem("Id");
//             const schemaRes = await merchantSchema(token.access_token, id);
//             if (schemaRes.message === "success") {
//                 let schemaCode;
//                 schemaRes.data.filter((element) => {
//                     if (element.schemaName !== "Referral") {
//                         schemaCode = element.schemaCode;
//                     }
//                     return schemaCode;
//                 });
//                 const body = {
//                     amount: receiptRes[0]?.total,
//                     email: props.email,
//                     schemacode: schemaCode,
//                 };
//                 const transRes = await transaction(token?.access_token, body);
//                 if (transRes.message === "Request processed successfully.") {
//                     alertModal("center", "success", "Receipt upload successfully", "button", "OK", 5000);
//                     setLoader(false);
//                 } else {
//                     alertModal("center", "error", "Receipt upload failed", "button", "OK", 5000);
//                 }
//                 setLoader(false);
//             } else {
//                 alertModal("center", "error", "Something went wrong please try later", "button", "OK", 5000);
//                 setLoader(false);
//             }
//         };
//         if (receiptRes && receiptRes[0]?.total > 0) {
//             fetch();
//         } else {
//             if (Array.isArray(receiptRes)) {
//                 alertModal("center", "warning", "Please upload a receipt", "button", "OK", 5000);
//                 setLoader(false);
//             }
//         }
//         //eslint-disable-next-line
//     }, [receiptRes]);
    

    const redeemGiftCard = async (detail) => {
        setLoader(true);
        const token = JSON.parse(localStorage.getItem("token_gen"));
            const id = localStorage.getItem("Id");
            const schemaRes = await merchantSchema(token.access_token, id);
            if (schemaRes.message === "success") {
                let schemaCode;
                schemaRes.data.filter((element) => {
                    if (element.schemaName !== "Referral") {
                        schemaCode = element.schemaCode;
                    }
                    return schemaCode;
                });
                const body = {
                    amount: response[0]?.subTotal,
                    email: email,
                    schemacode: schemaCode,
                };
                const transRes = await transaction(token?.access_token, body);
                if (transRes.message === "Request processed successfully.") {
                    alertModal("center", "success", `Receipt uploaded successfully and Earned ${transRes.data[0].qualifiedCampaign.Value} ${transRes.data[0].qualifiedCampaign.Type}`, "button", "OK", 2000);
                    setLoader(false);
                    setTimeout(() => {
                      navigate("/customer-portal")
                  }, 2000);
                  
                } else {
                    alertModal("center", "error", "Receipt upload failed", "button", "OK", 5000);
                }
                setLoader(false);
            } else {
                alertModal("center", "error", "Something went wrong please try later", "button", "OK", 5000);
                setLoader(false);
            }
}
  return (
    <div>
        {loader && <Loader />}
      
           
        <div style={{justifyContent:"center",alignItems:"center",display:"flex",marginTop:"10px"}}>
        <h5>Receipt Confirmation</h5>
        </div>
     
      {response?.length > 0 ? (
                <div style={{ margin: '20px' }}>
                  <table style={{ marginTop: "10px", width: "100%" }}>
                    <thead>
                      <tr>
                        <th style={{ border: '1px solid black', padding: '8px' }}>Item</th>
                        <th style={{ border: '1px solid black', padding: '8px' }}>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      {response[0]?.items?.map((item) => (
                       
                        <tr>
                          <td style={{ border: '1px solid black', padding: '8px' }}>{item?.name}</td>
                          <td style={{ border: '1px solid black', padding: '8px' }}>{item?.price}</td>
                        </tr>
                        
                      ))}

<tr>
                          <td style={{ border: '1px solid black', padding: '8px',fontWeight:"700" }}>Total</td>
                          <td style={{ border: '1px solid black', padding: '8px',fontWeight:"700" }}>{response[0]?.subTotal}</td>
                        </tr>
                    </tbody>
                  </table>
                  <div className='text-center mt-3'>
                    <button className='rds' onClick={redeemGiftCard}>Process</button>
                    <Link to="/customer-portal"><button className='mll-5 rds'>Cancel</button>
                    </Link>
                  </div>
                  <div style={{ textAlign:"center"}}> 
              <p style={{color:"black", marginTop: "54px"}}>If there is any issue with the uploaded receipt; the system did not recognize it. Please upload a new receipt or  <Link to ="/contact-us">contact support</Link>.</p>
              {/* <Link to ="/contact-us">Contact Us</Link> */}
              </div>
                </div>
              ):
              <div style={{ textAlign:"center"}}> 
              <p style={{color:"black", marginTop: "54px"}}>There is an issue with the uploaded receipt; the system did not recognize it. Please upload a new receipt or  <Link to ="/contact-us">contact support</Link>.</p>
              {/* <Link to ="/contact-us">Contact Us</Link> */}
              </div>}
    </div>
  )
}

export default RecieptResponse
