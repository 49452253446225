import React, { useEffect, useRef, useState } from "react";
import { Modal, Button, CloseButton } from "react-bootstrap";
import { alertModal, getDate } from "../../utils/helper";
import { decrypt } from "../../utils/encodingdecoding";
import { ValidatorForm } from "react-form-validator-core";
import TextValidator from "../partial/TextValidator";
import { customerView, transactionRedeem } from "../../services/services";
import Loader from "../partial/Loader";

const RedemptionModal = ({ show, close, setState, state }) => {
    const [loader, setLoader] = useState(false);
    const formRef = useRef();
    const customerCode = localStorage.getItem("c_code");
    const currentDate = new Date();
    const [redemptionState, setRedemptionState] = useState({
        redeemDetail: {
            walletType: "point",
            values: "",
            redemption_type: "Instore Redemption",
            unique_identifier: "",
            transactionType: "redeem",
            transactionDate: getDate(currentDate, "YYYY/MM/DD"),
        },
        customer: {
            customercode: customerCode,
        },
    });

    const { values, unique_identifier, successMsg } = redemptionState.redeemDetail;

    useEffect(() => {
        const validateName = (name) => {
            return /^[A-Za-z0-9\s&,$&]*$/.test(name);
        };
        ValidatorForm.addValidationRule("validId", (value) => {
            if (validateName(value)) {
                return true;
            }
            return false;
        });
    }, [unique_identifier]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "values") {
            if (value === "" || /^(?!0)[0-9]*$/.test(value)) {
                const floatValue = value === "" ? "" : parseFloat(value);
                setRedemptionState((prev) => ({
                    ...prev,
                    redeemDetail: {
                        ...prev.redeemDetail,
                        values: floatValue,
                    },
                }));
            }
        } else {
            setRedemptionState((prev) => ({
                ...prev,
                redeemDetail: {
                    ...prev.redeemDetail,
                    [name]: value,
                    unique_identifier: name === "unique_identifier" ? `Store-${value}` : prev.redeemDetail.unique_identifier,
                },
            }));
        }
    };

    const Submit = async () => {
        setLoader(true);
        const token = JSON.parse(localStorage.getItem("token_gen"));
        const response = await transactionRedeem(token.access_token, JSON.stringify(redemptionState));
        if (response.message === "Request processed successfully.") {
            const customerCode = localStorage.getItem("c_code");
            const customerRes = await customerView(token.access_token, customerCode);
            alertModal("center", "success", `${response?.data?.redeemAmount} points redeem successfully`, "button", "OK", 3000);
            setRedemptionState({
                redeemDetail: {
                    values: "",
                    unique_identifier: "",
                },
            });
            setState((prev) => ({
                ...prev,
                walletsDetail: customerRes?.walletsDetail,
            }));
            setLoader(false);
        } else {
            alertModal("center", "error", response.message, "button", "OK", 3000);
            setLoader(false);
        }
    };

    return (
        <>
            {loader && <Loader />}
            <Modal show={show} onHide={close} centered>
                <Modal.Header>
                    <Modal.Title style={{ fontSize: "20px" }}>In-Store Redemption</Modal.Title>
                    <CloseButton onClick={close} variant="white" className="newStyle" />
                </Modal.Header>
                <Modal.Body style={{ position: "relative", minHeight: "85px" }}>
                    <p>Upload Your Bills, Get Rewards</p>
                    {successMsg ? (
                        <span className="successMessage">{successMsg}</span>
                    ) : (
                        <ValidatorForm className="signUpPage__formSection" ref={formRef} onSubmit={Submit}>
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                    <TextValidator className="form-control" type="text" name="values" value={values} validators={["required"]} errorMessages={["Please enter amount"]} placeholder="Enter Amount" onChange={handleChange} />
                                    <div className="redemption-points">
                                        <div className="redemption-points__left">1075 points</div>
                                        <div className="redemption-points__right">
                                            <span className="redemption-points__right__equal">=</span>$ 1075
                                        </div>
                                    </div>
                                    <TextValidator className="form-control" type="text" name="unique_identifier" value={unique_identifier.replace("Store-", "")} placeholder="Enter Store ID" validators={["required", "validId"]} errorMessages={["Please enter store id", "Please enter a valid id"]} onChange={handleChange} />
                                </div>
                            </div>
                            <Button type="submit" disabled={!values || !unique_identifier || values > state.walletsDetail.TotalavailableValue} style={{ float: "inline-end", marginTop: "10px" }}>
                                Submit
                            </Button>
                        </ValidatorForm>
                    )}
                </Modal.Body>
            </Modal>
        </>
    );
};

export default RedemptionModal;
