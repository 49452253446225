import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { backArrow } from '../../Image/Index';
import { merchantSchema, transaction, transactionRedeem } from '../../services/services';
import { alertModal, getDate } from '../../utils/helper';
import Loader from '../partial/Loader';
import swal from "sweetalert";
import { ValidatorForm } from 'react-form-validator-core';
import TextValidator from '../partial/TextValidator';
import TextAreaValidator from '../partial/TextAreaValidator';
import { contactus } from '../../services/auth';

function ContactUs() {
        const navigate = useNavigate(); 
    const nameContact = localStorage.getItem("name")
    const nameEmail = localStorage.getItem("email")
    const mobile = localStorage.getItem("mobile")
    const [formData, setFormData] = useState({
        contactNumber: mobile,
        projectdetails: "",
        name: nameContact,
        email: nameEmail,
        subject: "",
        ip: "",
        country: "",
    });
    const [loader, setLoader] = useState(false);
  
    const onChange = (e) => {
        setFormData({ ...formData, ...{ [e.target.name]: e.target.value } });
    };
    const { name, email, contactNumber, subject, projectdetails } = formData;

    const onSubmit = async () => {
        setLoader(true);
        let token = await JSON.parse(localStorage.getItem("token_gen"));
        let response = await contactus(token?.access_token, formData);
        setLoader(false);
        if (response?.message === "Details Submitted Sucessfully") {
            swal({
                position: "center",
                icon: "success",
                title: response?.message,
                showConfirmButton: false,
                timer: 5000,
            });
            navigate("/customer-portal")
            setFormData({ ...formData, subject: "", projectdetails: "" });
        } else {
            swal({
                position: "center",
                icon: "error",
                title: response?.message,
                showConfirmButton: false,
                timer: 5000,
            });
        }

    };


    return (
        <div>
            {loader && <Loader />}


            <div style={{ justifyContent: "center", alignItems: "center", display: "flex", marginTop: "10px" }}>
                <h5>Contact Us</h5>
            </div>

            <div className="bookademopopupbox" style={{ paddingTop: "0px" }}>
                <div className="bookademo_formbox">
                    <ValidatorForm className="signUpPage__formSection" onSubmit={onSubmit}>
                        <TextValidator className="form-control" placeholder="Enter Subject " name="subject" value={subject} maxLength="50" onChange={onChange} validators={["required"]} errorMessages={["Subject is required."]} />
                        <TextAreaValidator placeholder="Description" className="form-control" name="projectdetails" value={projectdetails} onChange={onChange} validators={["required"]} errorMessages={["Please enter details about your project",]} />
                        <button type="submit" className="">
                            Submit
                        </button>
                    </ValidatorForm>
                </div></div>
        </div>
    )
}

export default ContactUs

