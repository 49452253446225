import React, { useState, useEffect, useRef } from "react";
import { orderHistory, customerView, getGeneratedVoucher, getBanner, getCampaigns, voucherTemplateDetails, getCustomerGiftcard, getUploadData, fileDataUpload } from "../../services/services";
import UploadModal from "./UploadModal";
import RedemptionModal from "./RedemptionModal";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../partial/Loader";
import { alertModal, getDate } from "../../utils/helper";
import { useLocation } from "react-router-dom";
import { giftCard, burger, share, backArrow, voucher } from "../../Image/Index";
import moment from "moment";
import Page from "./Page";
import { decrypt, encrypt } from "../../utils/encodingdecoding";
import Slider from "react-slick";
import Home from "./Home";
import Footer1 from "./Footer1";
import { getProfileData } from "../../services/auth";
import CopyToClipboard from "react-copy-to-clipboard";
import { EmailShareButton, FacebookMessengerShareButton, WhatsappShareButton, WhatsappIcon, FacebookMessengerIcon, LinkedinShareButton, LinkedinIcon, EmailIcon } from "react-share";
import RedeemCard1 from "./RedeemCard1";
import UploadRewards from "./UploadRewards";
import ClipLoader from "react-spinners/ClipLoader";
import { Button, CloseButton, Modal } from "react-bootstrap";
import TextValidator from "../partial/TextValidator";
import { ValidatorForm } from "react-form-validator-core";
import PageData from "./PageData";

const CustomerPortal = () => {
    const [show, setShow] = useState(false);

    const [loading, setLoading] = useState(false);
    const [membershipData, setMemberShipData] = useState()
    const [brandName, setBrandName] = useState("");
    const receiptRef = useRef();
    const [uploadDatas, setUploadDatas] = useState(false)
    const location = useLocation();
    const [data, setData] = useState();
    const [orderList, setOrderList] = useState([]);
    const [uploadRewards, setUploadRewards] = useState(false)
    const navigate = useNavigate();

    const [bannerData, setBannerData] = useState([]);
    const [rewardData, setRewardData] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);

    const [currentData, setCurrentData] = useState(0);
    const [loader, setLoader] = useState(true);
    const [uploadedData, setUploadedData] = useState()
    const [redeemLoader, setRedeemLoader] = useState(false);
    const [state, setState] = useState({
        campaigns: [],
        activityLogs: [],
        referralCode: "",
        rewards: [],
        uploadModal: false,
        redemptionModal: false,
        flag: true,
        choose_rewards_flag: true,
        email: "",
        banner: [],
        displayUploadReceipt: false,
        displayStoreRedemption: false,
        template: [],
        pointImg: false,
        walletSummary: [],
        walletsDetail: {},
        socialMedia: [],
        transactions: [],
    });
    const [stateRewards, setStateRewards] = useState({
        template: [],
    });
    const [rewardState, setRewardState] = useState({
        giftCards: [],
        detail: "",
        type: "",
    });
    const [showDetail, setShowDetail] = useState(false);
    const [selectedValue, setSelectedValue] = useState();
    const [isShareOpen, setIsShareOpen] = useState(false);
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
    };
    //   const logo = async () => {
    //     setLoader(true)
    //     const token = JSON.parse(localStorage.getItem("token_gen"));
    //     const id = localStorage.getItem("Id");
    //     await getBanner(token.access_token, id).then((res) => {
    //       setBannerData(res[0]?.bannerDetails)
    //       setLoader(false)
    //     })
    //   }

    //   useEffect(() => {
    //     logo()
    //   }, [])

    const [redeemReward, setRedeemReward] = useState(false);
    const [matchedVoucherStatus, setMatchedVoucherStatus] = useState([]);
    const dataName = decrypt(localStorage.getItem("uploadedSource"));

    const pageSize = 10;
    const pageCount = Math.ceil(matchedVoucherStatus?.length / pageSize);

    const pageLength = 10;
    const pageData = Math.ceil(uploadedData?.length / pageLength);

    const [buttons, setButtons] = useState({
        home: true,
        choose_rewards: false,
        inStore: false,
    });
    const [homeState, setHomeState] = useState({
        showModal: false,
        history: [],
        enableCamp: [],
        linkUrl: [
            {
                camp: [],
                url: "",
            },
        ],
        disableCamp: [],
    });

    const [toggle, setToggle] = useState({
        isActive: false,
        index: null,
    });
    const [isIcon, setIsIcon] = useState(false);

    const { isActive, index } = toggle;

    const toggleActive = (ind) => {
        if (index === ind) {
            setToggle({ ...toggle, index: ind, isActive: !isActive });
        } else {
            setToggle({ ...toggle, index: ind, isActive: true });
        }
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const tab = queryParams.get("tab");
        if (tab) {
            setActiveTab(tab);
        }
    }, [location.search]);

    const [shareState, setShare] = useState({
        shareUrl: "",
        copied: false,
    });
    const { showModal, history, enableCamp, disableCamp, linkUrl } = homeState;

    const { referralCode, uploadModal, redemptionModal, campaigns, email, banner, displayUploadReceipt, displayStoreRedemption, pointImg, walletsDetail, socialMedia, rewards, activityLogs } = state;
    const { home, choose_rewards, inStore } = buttons;
    const { shareUrl, copied } = shareState;
    const { template } = stateRewards;
    const { giftCards, detail, type } = rewardState;

    const [activeTab, setActiveTab] = useState("overview");

    useEffect(() => {
        setShare((prev) => ({
            ...prev,
            code: referralCode,
            shareUrl: referralCode,
        }));
    }, [referralCode]);

    useEffect(() => {
        if (activeTab === "redemption") {
            if (selectedValue) {
                if (location?.stateRewards?.type === "internal") {
                    setSelectedValue("internal");
                } else {
                    setSelectedValue("external");
                }
            }
        }

        // eslint-disable-next-line
    }, [activeTab]);

    useEffect(() => {
        const fetch = async () => {
            setRedeemLoader(true);
            let tempTemplate = [];
            const token = JSON.parse(localStorage.getItem("token_gen"));
            await voucherTemplateDetails(token?.access_token)
                .then((res) => {
                    if (res?.data) {
                        const currentDate = new Date();
                        res?.data?.map((e) => e.isActive && new Date(e.voucherVisibilityDate) <= currentDate && tempTemplate.push(e));
                    }
                    setStateRewards((prevState) => ({
                        ...prevState,

                        template: tempTemplate,
                        //  walletsDetail: location?.stateRewards?.walletsDetail,
                    }));
                })
                .catch(() => { });

            const id = localStorage.getItem("Id");
            await getCustomerGiftcard(id)
                .then((res) => {
                    setRewardState((prevState) => ({
                        ...prevState,
                        giftCards: res?.data,
                    }));
                })
                .catch(() => { });
            setRedeemLoader(false);
        };
        if (activeTab === "redemption") {
            fetch();
        }
    }, [activeTab]);

    useEffect(() => {
        if (location?.state?.home) {
            setButtons((prev) => ({
                ...prev,
                home: location?.state?.home,
                choose_rewards: location?.state?.choose_rewards,
                inStore: false,
            }));
        }
    }, [location]);

    const internalFun = () => {
        return (
            <div>
                {loader ? (
                    ""
                ) : template?.length > 0 ? (
                    <div className="internal_voucher_row">
                        {template
                            ?.filter((vou) => vou.title.includes("purchase"))
                            .map((vou, i) => (
                                <div className="internal_voucher_box" key={i}>
                                    <Link
                                        to=""
                                        stateRewards={{ selectedValue }}
                                        onClick={() => {
                                            setRewardState((prev) => ({
                                                ...prev,
                                                detail: { ...vou, earningValue: vou.earningValue },
                                                type: "voucher",
                                            }));
                                            setShowDetail((prev) => !prev);
                                        }}
                                    >
                                        <div className="internal_voucher_ibox">
                                            <img src={vou?.imageUpload ? vou.imageUpload : voucher} alt="" />
                                        </div>
                                        <div className="internal_voucher_cbox">
                                            <h5>{vou.name}</h5>
                                            <p dangerouslySetInnerHTML={vou.description ? { __html: vou.description } : null}></p>
                                        </div>
                                    </Link>
                                </div>
                            ))}
                    </div>
                ) : (
                    <h6 className="text-center">No Record Found</h6>
                )}
            </div>
        );
    };

    const externalFun = () => {
        return (
            <div>
                {loader ? (
                    ""
                ) : giftCards?.length > 0 ? (
                    <div className="internal_giftcard_row">
                        {giftCards?.map((ele, index) => (
                            <div className="internal_giftcard_box" key={index}>
                                <Link
                                    to=""
                                    stateRewards={{ selectedValue }}
                                    onClick={() => {
                                        setRewardState((prev) => ({
                                            ...prev,
                                            detail: ele,
                                            type: "giftCard",
                                        }));
                                        setShowDetail(() => !showDetail);
                                    }}
                                >
                                    <div className="internal_giftcard_ibox">{<img src={ele?.image ? ele?.image : giftCard} alt={ele.title} />}</div>
                                    <div className="internal_giftcard_cbox">
                                        <h5>{ele.title}</h5>
                                    </div>
                                </Link>
                            </div>
                        ))}
                    </div>
                ) : (
                    <h6 className="text-center">No Record Found</h6>
                )}
            </div>
        );
    };

    const handleSelect = (e) => {
        if (e.target.value === "internal") {
            setSelectedValue(e.target.value);
        } else if (e.target.value === "external") {
            setSelectedValue(e.target.value);
        } else {
            setSelectedValue("");
        }
    };

    useEffect(() => {
        const sortedLogs = activityLogs?.filter((ele) => ele.transactionType && ele)?.sort((a, b) => new Date(b.transctionDate) - new Date(a.transctionDate));

        setHomeState((prev) => ({ ...prev, history: sortedLogs }));
    }, [activityLogs]);

    useEffect(() => {
        const fetch = async () => {
            const token = JSON.parse(localStorage.getItem("token_gen"));
            const customerCode = localStorage.getItem("c_code");
            const id = localStorage.getItem("Id");
            const responseData = await getProfileData(token?.access_token, id);
            setData(responseData);
            const banner = await getBanner(token?.access_token, id);
            let profileData = customerView(token?.access_token, customerCode);


            let camp = getCampaigns(token?.access_token, id);

            const body = {
                customercode: customerCode,
            };

            Promise.all([profileData, camp]).then((values) => {
                profileData = values[0];
                camp = values[1];
                setMemberShipData(profileData.customer?.membership)
                setState((prevState) => ({
                    ...prevState,
                    campaigns: camp,
                    logo: localStorage.getItem("logo"),
                    uploadedSource: localStorage.getItem("uploadedSource"),
                    activityLogs: profileData?.activityLogs,
                    walletSummary: profileData?.WalletSummary?.filter((wallet) => getDate(wallet?.redeemableAfterDate, "YYYY-MM-DD") > getDate(new Date(), "YYYY-MM-DD") && wallet),
                    walletsDetail: profileData?.walletsDetail,
                    referralCode: profileData?.customer?.referralcode,
                    flag: false,
                    rewards: rewards,
                    email: profileData?.customer?.email,
                    banner: banner[0]?.bannerDetails,
                    displayUploadReceipt: banner[0]?.isReciptUpload,
                    displayStoreRedemption: banner[0]?.inStoreRedeption,
                    pointImg: banner[0]?.isPointImg,
                    socialMedia: banner[0]?.socialMedia,
                }));
                setLoader(false);
            });
        };
        fetch();
    }, []);

    useEffect(() => {
        const fetchOrderHistory = async () => {
            if (activeTab === "giftcard") {
                setLoading(true);
                const customerCode = localStorage.getItem("c_code");
                await orderHistory(customerCode, encrypt("Novus1f4-87bg-4b69-be25-31240m8fsaas")).then((res) => {
                    setOrderList(res.data);
                    setLoading(false);
                });
            }
        };
        fetchOrderHistory();
    }, [activeTab]);



    const fetch = async () => {
        if (activeTab === "uploaded") {
            setLoading(true);
            let tempToken = JSON.parse(localStorage.getItem("token_gen"));
            tempToken = tempToken?.access_token;
            const id = localStorage.getItem("Id");
            const email = localStorage.getItem("email");
            await getUploadData(tempToken, id, email).then((res) => {
                setUploadedData(res?.data);
                setLoading(false);
            });
        }
    };
    useEffect(() => {
        fetch();
    }, [activeTab]);


    useEffect(() => {
        const fetchVoucherHistory = async () => {
            if (activeTab === "voucher") {
                setLoading(true);
                const token = JSON.parse(localStorage.getItem("token_gen"));
                const customerCode = localStorage.getItem("c_code");
                await getGeneratedVoucher(token?.access_token, customerCode).then((res) => {
                    setMatchedVoucherStatus(res?.data?.data.customerData.voucherData);
                    setLoading(false);
                });
            }
        };
        fetchVoucherHistory();
    }, [activeTab]);

    const handleCopy = () => {
        setShare((prev) => ({ ...prev, copied: true }));
        setTimeout(() => {
            setShare((prev) => ({ ...prev, copied: false }));
        }, 3000);
    };
    const [stateData, setStateData] = useState({
        selectedFile: "",
        size: false,
        successMsg: false,
        receiptRes: "",
    });
    const { selectedFile, size, successMsg, receiptRes } = stateData;
    const handleUpload = () => {
        setShow(true);
    };
    const handleClose = () => {
        setShow(false);
    }
    const handleFile = (e) => {
        if (e.target.files[0] && e.target.files[0]?.size > 1 * 1024 * 1024) {
            setStateData({ size: true });
        } else {
            const file = e.target.files[0];
            setStateData((prev) => ({
                ...prev,
                size: false,
                selectedFile: file,
            }));
        }
    };

    const Submit = async () => {
        const token = JSON.parse(localStorage.getItem("token_gen"));
        const id = localStorage.getItem("Id");
        const email = localStorage.getItem("email");
        if (!size) {
            setLoader(true);
            const fileRes = await fileDataUpload(token?.access_token, id, email, brandName, selectedFile);
            if (fileRes.message === "Image uploaded and data stored successfully.") {
                alertModal("center", "success", "Image uploaded and data stored successfully..", "button", "OK", 5000);
                setLoader(false);
                receiptRef.current.value = "";
                setStateData({
                    selectedFile: "",
                    size: false,
                    successMsg: false,
                    receiptRes: "",
                })
                setShow(false)
                fetch()
                setBrandName("")

            } else {
                alertModal("center", "error", "Something went wrong please try again.", "button", "OK", 5000);
                receiptRef.current.value = "";
                setLoader(false);
            }
        }
    };
    const handleSignup = () => {
        localStorage.clear()
        sessionStorage.clear()
        navigate("/signin")
    }

    const handleMemberShip = () => {
        const type = "VIP"
        navigate("/membership", { state: { data: data, type: type } })
    }
    const handleMemberShipData = () => {
        const typeData = "VVIP"
        navigate("/membership", { state: { data: data, type: typeData } })
    }
    const id = localStorage.getItem("Id");

    const onChangeData = (e) => {
        setBrandName(e.target.value);
    };
    return (
        <>
            {loader && <Loader />}
            <div className="customer_portal__mainPage">
                <Slider {...settings}>
                    {banner?.map((item, index) => (
                        <div className="hero-banner" key={index}>
                            <img src={item?.blobFilePath} alt={`banner-${index}`} />
                        </div>
                    ))}
                </Slider>

                {/* <div className="hero-banner">
                    <img src={"/Images/website_banner.png"} alt="Hero Banner" />
                </div> */}
                <>
                    <div className="new_header_main" id="new_header_main" setLoader={setLoader} loader={loader}>

                        {
                            id === "MER000289" && !membershipData && (
                                <div>
                                    <Modal show={true} centered  >
                                        <Modal.Header>
                                            <Modal.Title style={{ fontSize: "20px" }}>Join MemberShip of Paradise</Modal.Title>
                                            <CloseButton onClick={handleSignup} variant="white" />
                                        </Modal.Header>
                                        <Modal.Body style={{ position: "relative", minHeight: "85px" }}>
                                            <div className="d-flex justify-content-center" style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                                                <Button className="btn btn-primary btn-lg" onClick={handleMemberShip}>VIP</Button>
                                                <button className="btn btn-success ml-5 btn-lg" onClick={handleMemberShipData}>VVIP</button>
                                            </div>

                                        </Modal.Body>

                                    </Modal>

                                </div>
                            )
                        }

                        <div className="header_menu_box">
                            <div className="header_points_box">
                                <h4>
                                    Your Points <img src={"/Images/star-silver.png"} /> <span>{walletsDetail?.availableValue}</span>
                                </h4>
                            </div>

                        </div>
                        {
                            show && (
                                <Modal show={show} onHide={handleClose} centered>
                                    <Modal.Header>
                                        <Modal.Title style={{ fontSize: "20px" }}>Upload & Earn Rewards</Modal.Title>
                                        <CloseButton onClick={handleClose} variant="white" />
                                    </Modal.Header>
                                    <Modal.Body style={{ position: "relative", minHeight: "85px" }}>
                                        <p>Upload a product photo with invoice and Earn Rewards!</p>

                                        {successMsg ? (
                                            <span className="successMessage">{successMsg}</span>
                                        ) : (
                                            <form className="customForm">
                                                <div className="row">
                                                    <label>Invoice Title</label>
                                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                        <input placeholder="Title" className="form-control" name="brandName" required value={brandName} onChange={onChangeData} />

                                                    </div>
                                                    <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-4">
                                                        <input className="form-control" type="file" ref={receiptRef} accept={".jpeg, .jpg, .pdf,.PNG"} required onChange={handleFile} />
                                                        {size && <span className="errorMessage">File size not more than 1 MB</span>}
                                                    </div>
                                                </div>
                                            </form>
                                        )}
                                    </Modal.Body>
                                    {!successMsg && (
                                        <Modal.Footer>
                                            <button type="submit" className="btn btn-primary" disabled={selectedFile && brandName ? false : true} onClick={Submit}>
                                                Upload
                                            </button>
                                        </Modal.Footer>
                                    )}
                                </Modal>
                            )
                        }

                        {displayUploadReceipt && (
                            <div className="d-flex justify-content-end">
                                <button
                                    className="btn btn-primary btn-lg uploadReceiptBtn"
                                    onClick={() =>
                                        setState((prev) => ({
                                            ...prev,
                                            uploadModal: true,
                                        }))
                                    }
                                >
                                    <i className="fas fa-upload"></i>
                                </button>
                            </div>
                        )}

                        {uploadModal && (
                            <UploadModal
                                email={email}
                                show={uploadModal}
                                close={() =>
                                    setState((prev) => ({
                                        ...prev,
                                        uploadModal: false,
                                    }))
                                }
                            />
                        )}
                        {redemptionModal && (
                            <RedemptionModal
                                state={state}
                                setState={setState}
                                show={redemptionModal}
                                close={() =>
                                    setState((prev) => ({
                                        ...prev,
                                        redemptionModal: false,
                                    }))
                                }
                            />
                        )}
                        <div className="header_menu_list">
                            <div className="highlighted_box">
                                <a
                                    className={`nav-link highlighted cursor-pointer`}
                                    onClick={() => {
                                        setActiveTab("redemption");
                                        setIsIcon(false);
                                    }}
                                >
                                    Redeem Rewards
                                </a>
                            </div>


                            <div className="iconbox">
                                <img src={burger} onClick={() => setIsIcon(!isIcon)} />
                            </div>
                            <div className={isIcon ? "mobile_box active" : "mobile_box"}>
                                <ul className="nav nav-tabs" id="tabs-nav" role="tablist">
                                    {/* <li className="nav-item redeemLinkBtn active" role="presentation">
                                        <a
                                            href="#"
                                            className={`nav-link ${activeTab === "redemption" ? "active" : ""}`}
                                            id="redemption-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#redemption"
                                            role="tab"
                                            aria-controls="home"
                                            aria-selected={activeTab === "redemption"}
                                            onClick={() => {
                                                setActiveTab("redemption");
                                                // setIsIcon(false);
                                            }}
                                        >
                                            Redeem Rewards
                                        </a>
                                    </li> */}
                                    <li className="nav-item active" role="presentation">
                                        <a
                                            href="#"
                                            className={`nav-link ${activeTab === "overview" ? "active" : ""}`}
                                            id="overview-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#overview"
                                            role="tab"
                                            aria-controls="home"
                                            aria-selected={activeTab === "overview"}
                                            onClick={() => {
                                                setActiveTab("overview");
                                                setIsIcon(false);
                                            }}
                                        >
                                            Overview
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a
                                            href="#"
                                            className={`nav-link ${activeTab === "history" ? "active" : ""}`}
                                            id="history-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#history"
                                            role="tab"
                                            aria-controls="profile"
                                            aria-selected={activeTab === "history"}
                                            onClick={() => {
                                                setActiveTab("history");
                                                setIsIcon(false);
                                            }}
                                        >
                                            History
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a
                                            href="#"
                                            className={`nav-link ${activeTab === "voucher" ? "active" : ""}`}
                                            id="voucher-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#voucher"
                                            role="tab"
                                            aria-controls="contact"
                                            aria-selected={activeTab === "voucher"}
                                            onClick={() => {
                                                setActiveTab("voucher");
                                                setIsIcon(false);
                                            }}
                                        >
                                            Vouchers
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <a
                                            href="#"
                                            className={`nav-link ${activeTab === "giftcard" ? "active" : ""}`}
                                            id="giftcard-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#giftcard"
                                            role="tab"
                                            aria-controls="contact"
                                            aria-selected={activeTab === "giftcard"}
                                            onClick={() => {
                                                setActiveTab("giftcard");
                                                setIsIcon(false);
                                            }}
                                        >
                                            Gift Cards
                                        </a>
                                    </li>
                                    {/* <li className="nav-item">
                                        <a
                                            href="#"
                                            className={`nav-link ${activeTab === "uploaded" ? "active" : ""}`}
                                            id="uploaded-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#uploaded"
                                            role="tab"
                                            aria-controls="profile"
                                            aria-selected={activeTab === "uploaded"}
                                            onClick={() => {
                                                setActiveTab("uploaded");
                                                setIsIcon(false);
                                            }}
                                        >
                                            Uploaded Invoice
                                        </a>
                                    </li> */}
                                    {displayStoreRedemption && (
                                        <li className="nav-item">
                                            <a
                                                href="#"
                                                onClick={() => {
                                                    setButtons({ inStore: true });
                                                    setState((prev) => ({ ...prev, redemptionModal: true }));
                                                    setActiveTab("redemption");
                                                    setIsIcon(false);
                                                }}
                                                className={`nav-link ${activeTab === "redemption" ? "active" : ""}`}
                                                id="redemption-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#redemption"
                                                role="tab"
                                                aria-controls="contact"
                                                aria-selected={activeTab === "redemption"}
                                            >
                                                In-Store Redemption
                                            </a>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="customer_tab_content">

                    {loading ? (<div className="loader-container" style={{ display: "flex", justifyContent: "center", alignItems: "center", padding:"10px"}}>
                                <ClipLoader
                                    color={'#D000218'}
                                    loading={loading}

                                    size={50}

                                />
                            </div>
                            ) : (
                        <div className="tab-content" id="tabs-content">
                            <div className="share_box">
                                <h6 className="sharing_text">
                                    Share your Referral Code: <span>{referralCode}</span>
                                </h6>
                                <div className="sharing_box">
                                    <CopyToClipboard text={referralCode} onCopy={handleCopy}>
                                        <div className="copy_btn">
                                            {copied ? (
                                                <div>
                                                    <i title="copied" className="fa-solid fa-copy"></i>
                                                    <span className="copy_msg">Referral Code Copied</span>
                                                </div>
                                            ) : (
                                                <i title="copy" className="fa-regular fa-copy"></i>
                                            )}
                                        </div>
                                    </CopyToClipboard>
                                    <EmailShareButton url={shareUrl} className="sharing_icon">
                                        <EmailIcon size={32} round={true} />
                                    </EmailShareButton>
                                    <FacebookMessengerShareButton url={shareUrl} className="sharing_icon">
                                        <FacebookMessengerIcon size={32} round={true} />
                                    </FacebookMessengerShareButton>
                                    <WhatsappShareButton url={shareUrl} className="sharing_icon">
                                        <WhatsappIcon size={32} round={true} />
                                    </WhatsappShareButton>
                                    <LinkedinShareButton url={shareUrl} className="sharing_icon">
                                        <LinkedinIcon size={32} round={true} />
                                    </LinkedinShareButton>
                                </div>
                            </div>

                            <div className={`tab-pane ${activeTab === "redemption" ? "active" : ""}`} id="redemption" role="tabpanel" aria-labelledby="redemption-tab">
                                {!showDetail ? (
                                    <div className="allCardContainer position-relative">
                                        {redeemLoader ? (
                                            <Loader />
                                        ) : (
                                            <>
                                                <div className="function_row">
                                                    <div className="function_back">
                                                        <Link className="backAro" onClick={() => setActiveTab("overview")}>
                                                            <img src={backArrow} alt="Back" height={16} />
                                                        </Link>
                                                    </div>
                                                    <div className="d-flex justify-content-end align-items-center px-1">
                                                        <div className="chooseVc">Choose a Giftcard/Voucher</div>
                                                        <div>
                                                            <select className="form-select form-select-sm" name="vouchers" id="vouchers" value={selectedValue} onChange={handleSelect}>
                                                                <option value="all">All</option>
                                                                <option value="internal">Local Merchant Voucher</option>
                                                                <option value="external">International Voucher</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="allCardContainer__top">
                                                    {selectedValue === "internal" ? (
                                                        internalFun()
                                                    ) : selectedValue === "external" ? (
                                                        externalFun()
                                                    ) : (
                                                        <div>
                                                            {loader ? (
                                                                ""
                                                            ) : (
                                                                <h6 className="voucher-heading">
                                                                    <span>Local Merchant Voucher</span>
                                                                </h6>
                                                            )}
                                                            {internalFun()}
                                                            {loader ? (
                                                                ""
                                                            ) : (
                                                                <h6 className="voucher-heading">
                                                                    <span>International Voucher</span>
                                                                </h6>
                                                            )}
                                                            {externalFun()}
                                                        </div>
                                                    )}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                ) : (
                                    <RedeemCard1 detail={detail} type={type} walletsDetail={walletsDetail} setShowDetail={setShowDetail} data={data} />
                                )}
                            </div>
                            <div className={`tab-pane ${activeTab === "overview" ? "active" : ""}`} id="overview" role="tabpanel" aria-labelledby="overview-tab">
                                <div className="customer_portal__mainPage">
                                    <div className="customer_portal__content">
                                        <div className="tabs_box">{(home || inStore) && <Home state={state} setState={setState} />}</div>
                                    </div>
                                </div>
                                <div className="section_strip">
                                    <h2>EARN AND REDEEM VOUCHERS ACROSS THE {data?.companyname}</h2>
                                </div>

                                <div className="section_2_box">
                                    <div className="inner_section_2">
                                        <h3>Redeem {data?.companyname}</h3>
                                        <p>Enjoy special offers and discounts at any one of our discount partners, internationally and locally</p>
                                        <div className="rewards_main_box_2">
                                            <div className="rewards_desbox_2">
                                                <img src={"/Images/maritime_giftcard.png"} />
                                                <h5>International Gift Card</h5>
                                                <ul>
                                                    <li>
                                                        <strong>Global Brands at Your Fingertips:</strong> Travel through a diverse range of international gift card choices
                                                    </li>
                                                    <li>
                                                        <strong>Worldwide Shopping Spree:</strong> Choose from a plethora of gift cards for your shopping adventure
                                                    </li>
                                                    <li>
                                                        <strong>Unlimited Choices, Endless Possibilities:</strong> Open doors to unique and exciting purchases worldwide{" "}
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="rewards_desbox_2">
                                                <img src={"Images/maritime_giftcard.png"} />
                                                <h5>Local Gift Card and Voucher</h5>
                                                <ul>
                                                    <li>
                                                        <strong>Shop and Save with {data?.companyname}:</strong> Get special offers and discounts across {data?.companyname}, including Food Giant Supermarket!
                                                    </li>
                                                    <li>
                                                        <strong>Exclusive Savings, Local Flavour:</strong> Enjoy discounts from your favourite local spots
                                                    </li>
                                                    <li>
                                                        <strong>Discover Local Gems:</strong> Uncover new favourites and support local treasures with great discounts
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`tab-pane ${activeTab === "history" ? "active" : ""}`} id="history" role="tabpanel" aria-labelledby="history-tab">
                                <div className="tab_boxx">
                                    <div className="history__completed">
                                        <div className="history__completedList d-block">
                                            {history?.length ? (
                                                <ul className="list_all">
                                                    {history?.map((ele, i) => (
                                                        <li key={i}>
                                                            <div className="transaction_box">
                                                                <div className="transaction_details ">
                                                                    <div className="history_details">
                                                                        <h5 style={{ fontSize: "0.8rem", fontWeight: "700" }}>Trans ID: {ele.transactionId}</h5>
                                                                    </div>
                                                                    <div className="transaction_status">
                                                                        <span className="transaction_time">{moment(ele.transctionDate).format("YYYY-MM-DD HH:mm:ss a")}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="history__points">
                                                                    <div className="transaction_points">
                                                                        <span className="tp_value refunded">{ele.transactionType === "redeem" ? "Point Redeem" : ele.transactionType === "purchase" ? "Earned Value" : ele.transactionType === "Refund" ? "Point Refunded against purchase" : ele.transactionType === "VoucherPurchase" ? "Voucher purchased" : ele.transactionType === "VoucherRedeem " ? "Voucher redeem" : ele.transactionType === "purchase " ? "Point Earned" : ele.transactionType === "Settled " ? "Transaction Settled" : ele.action}</span>
                                                                        {ele.transactionType !== "VoucherPurchase" &&
                                                                            (ele.transactionType === "redeem" ? (
                                                                                <span className="redeemed">
                                                                                    -{ele.value} {ele.valueType}
                                                                                </span>
                                                                            ) : (
                                                                                <span className="refunded">
                                                                                    +{ele.value} {ele.valueType}
                                                                                </span>
                                                                            ))}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            ) : (
                                                <h6 className="text-center mt-4"> No Record Found</h6>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`tab-pane ${activeTab === "voucher" ? "active" : ""}`} id="voucher" role="tabpanel" aria-labelledby="voucher-tab">
                                <div className="table-responsive" style={{ margin: "10px" }}>
                                    <table className="table table-striped table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Voucher Name</th>
                                                <th>Voucher Code</th>
                                                <th>Value</th>
                                                <th>Purchase Date</th>
                                                <th>Expire Date</th>
                                                <th>IsReedemed</th>
                                            </tr>
                                        </thead>
                                        {
                                            <tbody>
                                                {matchedVoucherStatus && matchedVoucherStatus.length > 0 ? (
                                                    matchedVoucherStatus
                                                        .sort(function (a, b) {
                                                            if (a.createdDate === null) {
                                                                return 1;
                                                            } else if (b.createdDate === null) {
                                                                return -1;
                                                            }
                                                            const dateA = new Date(a.createdDate);
                                                            const dateB = new Date(b.createdDate);
                                                            if (dateA < dateB) {
                                                                return 1;
                                                            } else if (dateA > dateB) {
                                                                return -1;
                                                            } else {
                                                                const timeA = dateA.getTime();
                                                                const timeB = dateB.getTime();
                                                                return timeB - timeA;
                                                            }
                                                        })
                                                        .slice(currentPage * pageSize, (currentPage + 1) * pageSize)
                                                        .map((statement, i) => (
                                                            <tr key={i}>
                                                                <td>{statement?.voucherName}</td>

                                                                <td>{statement?.voucherCode}</td>
                                                                <td>{statement?.voucherValue}</td>
                                                                <td>{getDate(statement?.createdDate, "DD-MM-YYYY")}</td>
                                                                <td>{getDate(statement?.voucherExpiry, "DD-MM-YYYY")}</td>
                                                                <td>{statement?.voucherRedeem == true ? "True" : "False"}</td>
                                                            </tr>
                                                        ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="6">
                                                            <h6 className="text-center">No Record Found</h6>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        }
                                    </table>
                                </div>

                                {matchedVoucherStatus && matchedVoucherStatus?.length > 0 && (
                                    <div>
                                        <div className="dashboard__paginationBox">
                                            <Page currentPage={currentPage} setCurrentPage={setCurrentPage} pageCount={pageCount} value={5} />
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className={`tab-pane ${activeTab === "giftcard" ? "active" : ""}`} id="giftcard" role="tabpanel" aria-labelledby="giftcard-tab">
                                {orderList?.length ? (
                                    <div className="giftcards_row">
                                        {orderList?.map((ele, ind) =>
                                            ele?.item_list.map((item) => (
                                                <div className={`giftcards_box ${index === ind && isActive === true ? "active" : ""}`} onClick={() => toggleActive(ind)} key={ind}>
                                                    <div className="giftcards_box_inner">
                                                        <div className="giftcards_ibox">
                                                            <img src={item?.image ? item?.image : giftCard} alt="gift card" />
                                                        </div>
                                                        <div className="giftcards_cbox">
                                                            <h4>{item?.name}</h4>
                                                            <p>
                                                                Quantity: <span>{item?.quantity}</span>
                                                            </p>
                                                            <ul>
                                                                <li>Pts: {ele?.total_points_redeemed}</li>
                                                                <li className="refunded">Order Placed</li>
                                                            </ul>
                                                            <small>Gift cards or vouchers once bought cannot be exchanged, refunded or cancelled.</small>
                                                        </div>
                                                    </div>
                                                    <div className="giftcards_obox">
                                                        <p>
                                                            Order ID: <strong>{ele?.code}</strong> Order On: <strong>{getDate(ele?.order_date, "YYYY-MM-DD hh:mm:ss")}</strong>
                                                        </p>
                                                    </div>
                                                </div>
                                            ))
                                        )}
                                    </div>
                                ) : (
                                    <h6 className="text-center mt-4"> No Record Found</h6>
                                )}
                            </div>
                            <div className={`tab-pane ${activeTab === "uploaded" ? "active" : ""}`} id="uploaded" role="tabpanel" aria-labelledby="history-tab">
                                <div className="header_points_box ">
                                    <div className="highlighted_box" >
                                        <Link
                                            className={`btn btn-primary cursor-pointer d-inline-block`}
                                            onClick={handleUpload}
                                        >
                                            Upload & Earn Rewards
                                        </Link>
                                    </div>
                                </div>
                                <div className="table-responsive" style={{ margin: "10px" }}>
                                    <table className="table table-striped table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Invoice Title</th>
                                                <th>Invoice Uploaded Date</th>
                                                <th>Invoice Image</th>
                                                <th>Remarks</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        {
                                            <tbody>
                                                {uploadedData && uploadedData.length > 0 ? (
                                                    uploadedData
                                                        .sort(function (a, b) {
                                                            if (a.uploadedDate === null) {
                                                                return 1;
                                                            } else if (b.uploadedDate === null) {
                                                                return -1;
                                                            }
                                                            const dateA = new Date(a.uploadedDate);
                                                            const dateB = new Date(b.uploadedDate);
                                                            if (dateA < dateB) {
                                                                return 1;
                                                            } else if (dateA > dateB) {
                                                                return -1;
                                                            } else {
                                                                const timeA = dateA.getTime();
                                                                const timeB = dateB.getTime();
                                                                return timeB - timeA;
                                                            }
                                                        })
                                                        .slice(currentData * pageLength, (currentData + 1) * pageLength)
                                                        .map((val, i) => (
                                                            <tr key={i}>
                                                                <td>{val?.invoicetitle}</td>

                                                                <td>{getDate(val.imageData.uploadedDate ? val.imageData.uploadedDate : "-", "DD-MM-YYYY")}</td>
                                                                <td>
                                                                    <Link target="_blank" to={val.imageData.blobImageUrl}>
                                                                        <i className="fa-solid fa-image"></i>
                                                                    </Link>
                                                                </td>
                                                                <td>{val.imageData.merchantFeedback ? val.imageData.merchantFeedback : "-"}</td>
                                                                <td>
                                                                    {val.imageData.isApproved === undefined ? "Under Process" : (val.imageData.isApproved ? "Approved" : "Rejected")}
                                                                </td>


                                                            </tr>
                                                        ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="6">
                                                            <h6 className="text-center">No Record Found</h6>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        }
                                    </table>
                                </div>

                                {uploadedData && uploadedData?.length > 0 && (
                                    <div>
                                        <div className="dashboard__paginationBox">
                                            <PageData currentData={currentData} setCurrentData={setCurrentData}
                                             pageData={pageData} value={5} />
                                        </div>
                                    </div>
                                )}
                            </div>
                            {displayStoreRedemption && <div className={`tab-pane ${activeTab === "redemption" ? "active" : ""}`} id="redemption" role="tabpanel" aria-labelledby="giftcard-tab"></div>}
                        </div>
                            )}
                    </div>
                    <div className="footer__Box">
                        <div className="inner_footer">
                            <div className="inner_footerbox inner_footerbox_1">
                                <h4>Quick Links</h4>
                                <ul>
                                    <li>
                                        <Link to="/customer-portal">Home</Link>
                                    </li>
                                    <li>
                                        <Link to="/Choose-Rewards">Rewards</Link>
                                    </li>
                                    <li>
                                        <Link to="/customer-portal?tab=history">History</Link>
                                    </li>
                                    <li>
                                        <Link to="/customer-portal?tab=voucher">Vouchers</Link>
                                    </li>
                                    <li>
                                        <Link to="/customer-portal?tab=giftcard">Gift Cards</Link>
                                    </li>
                                </ul>
                            </div>
                            {data?.location?.length > 0 ? (
                                <div className="inner_footerbox inner_footerbox_2">
                                    <h4>Our Location</h4>
                                    <p>{data?.location}</p>
                                </div>
                            ) : null}
                            <div className="inner_footerbox inner_footerbox_3">
                                <h4>Contact Us</h4>
                                <ul>
                                    <li>
                                        <a>{data?.contactnumber}</a>
                                    </li>

                                    <li>
                                        <Link to={`mailto:${data?.email}`}>{data?.email}</Link>
                                    </li>
                                    <li className="text-white">{data?.address}</li>
                                </ul>
                            </div>
                            <div className="social_links">
                                {socialMedia?.length &&
                                    socialMedia.map((ele, i) => (
                                        <span key={i}>
                                            {ele.socialMediaName === "facebook" && ele.status === true ? (
                                                <Link to={ele.socialMediaLink} target="_blank" className="social_links__icon facebook_ic">
                                                    <i className="fa-brands fa-facebook-f"></i>
                                                </Link>
                                            ) : ele.socialMediaName === "instagram" && ele.status === true ? (
                                                <Link to={ele.socialMediaLink} target="_blank" className="social_links__icon instagram_ic">
                                                    <i className="fa-brands fa-instagram"></i>
                                                </Link>
                                            ) : ele.socialMediaName === "linkedin" && ele.status === true ? (
                                                <Link to={ele.socialMediaLink} target="_blank" className="social_links__icon linkedin_ic">
                                                    <i className="fa-brands fa-linkedin-in"></i>
                                                </Link>
                                            ) : ele.socialMediaName === "twitter" && ele.status === true ? (
                                                <Link to={ele.socialMediaLink} target="_blank" className="social_links__icon twitter_ic">
                                                    <i className="fa-brands fa-twitter"></i>
                                                </Link>
                                            ) : null}
                                        </span>
                                    ))}
                            </div>
                        </div>
                        <div className="copyright_box">
                            <div className="copyright_innerbox">
                                <div className="copyright_textbox">
                                    <ul>
                                        <li>© 2024 The {data?.companyname}. All Rights Reserved</li>
                                        {/* <li><a href="https://maritimefinancial.com/privacy-policy/" target="_blank">Privacy Policy</a></li> */}
                                    </ul>
                                </div>
                                <div className="copyright_arrowbox">
                                    <a href="#" id="totopbutton">
                                        <span></span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            </div>
        </>
    );
};

export default CustomerPortal;




