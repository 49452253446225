import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { ValidatorForm } from "react-form-validator-core";
import TextValidator from "../partial/TextValidator";
import { decrypt, encrypt } from "../../utils/encodingdecoding";
import { getCampaignPromotion, getCustomerData, getMerchantAndLogo, ssoLogin } from "../../services/services";
import { Internal_Token, Token_Generator, getMerchantData, signIn } from "../../services/auth";
import Loader from "../partial/Loader";
import Slider from "react-slick";
import swal from "sweetalert";

import swiggy1 from "../../../src/Image/swiggy-fig-1.jpg";
import AuthenticationSliderLeft from "./common/AuthenticationSliderLeft";
import { resetWarningCache } from "prop-types";

function SignIn() {
    const location = useLocation();
    const formRef = useRef();
    // const [incorrectPasswordAttempts, setIncorrectPasswordAttempts] = useState(JSON.parse(sessionStorage.getItem("incorrectPasswordAttempts")) || 0);
    const navigate = useNavigate();
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [pswdFocus, setPswdFocus] = useState(false);
    const [formData, setFormData] = useState({
        email: "",
        password: "",
    });
    const [error, setError] = useState("");
    const [loader, setLoader] = useState(false);
    const [merchantIds, setMerchantIds] = useState();
    const [logo, setLogo] = useState("");
    const [uploadSource, setUploadSource] = useState("");
    const [response, setResponse] = useState([]);

    const [responseData, setResponseData] = useState([]);
    const [searchParams] = useSearchParams();

    const { email, password } = formData;
    const subdomain = window.location.hostname.split(".")[0];

    useEffect(() => {
        const fetch = async () => {
            setLoader(true);
            const token = JSON.parse(localStorage.getItem("token_gen"));
            await getMerchantData(token?.access_token, subdomain).then((res) => {
                if (res.message === "success") {
                    setMerchantIds(res?.merchantcode);
                }
                // else{
                //     navigate("./")
                // }
            });

            setLoader(false);
        };
        fetch();
    }, [subdomain]);

    const onChange = (e) => {
        setFormData({ ...formData, ...{ [e.target.name]: e.target.value } });
    };

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    useEffect(() => {
        const ssoAuthLogin = async () => {
            const access_key = searchParams.get("access_key");
            const code = searchParams.get("code");
            const token = await Token_Generator();

            const response = await getMerchantAndLogo(token.access_token, merchantIds);
            if (response) {
                let tokenBody = {
                    clientid: response[0]?.merchantClientId,
                    grant_type: "client_credentials",
                    clientsecret: response[0]?.merchantClientSecret,
                };
                const internalToken = await Internal_Token(tokenBody);
                const ssoRes = await ssoLogin(internalToken.access_token, access_key);
                if (ssoRes.message === "Request processed successfully.") {
                    const getName = await getCustomerData(internalToken.access_token, { customercode: ssoRes.data.customercode });
                    if (getName.message === "Request processed successfully.") {
                        localStorage.setItem("name", getName.data.name);
                        localStorage.setItem("email", getName.data.email);
                        localStorage.setItem("Id", merchantIds);
                        localStorage.setItem("blob", response[0]?.blobFilePath);
                        localStorage.setItem("token_gen", JSON.stringify(internalToken));
                        localStorage.setItem("c_code", ssoRes.data.customercode);
                        localStorage.setItem("logo", encrypt(response[0]?.blobFilePath));
                        localStorage.setItem("clientsecret", response[0]?.merchantClientSecret);
                        localStorage.setItem("clientid", response[0]?.merchantClientId);
                        localStorage.setItem("uploadedSource", encrypt(response[0]?.uploadedSource));
                        setFavicon(response[0]?.blobFilePath);
                        setLogo(response[0]?.blobFilePath);
                        setUploadSource(response[0]?.uploadedSource);
                        navigate("/customer-portal");
                    } else {
                        setError(getName.message);
                    }
                } else {
                    setError(ssoRes?.message);
                }
            }
            setLoader(false);
        };

        const logo = async () => {
            if (merchantIds?.length > 0) {
                setLoader(true);

                const token = await Token_Generator();
                const response = await getMerchantAndLogo(token.access_token, merchantIds);
                // const responsee = await getCampaignPromotion(token.access_token, merchantIds);
                // setResponseData(responsee.data);
                localStorage.setItem("Id", merchantIds);
                localStorage.setItem("blob", response[0]?.blobFilePath);
                setResponse(response);
                localStorage.setItem("client_secret", response[0]?.merchantClientSecret);
                localStorage.setItem("client_id", response[0]?.merchantClientId);
                setFavicon(response[0]?.blobFilePath);
                setLogo(response[0]?.blobFilePath);
                setUploadSource(response[0]?.uploadedSource);
                setLoader(false);
            }
        };

        if (location?.pathname.includes("sso-login")) {
            ssoAuthLogin();
        } else {
            logo();
        }
        // eslint-disable-next-line
    }, [merchantIds]);

    const setFavicon = (imageSrc) => {
        const favicon = document.createElement("link");
        favicon.rel = "icon";
        favicon.type = "image/png";
        favicon.href = imageSrc;
        document.head.appendChild(favicon);
    };
    const onSubmit = async () => {
        const body = {
            email: email,
            password: password,
        };
        setLoader(true);
        let tokenBody = {
            clientid: localStorage.getItem("client_id"),
            grant_type: "client_credentials",
            clientsecret: localStorage.getItem("client_secret"),
        };

        const token = await Internal_Token(tokenBody);
        const res = await signIn(token?.access_token, body);
        if (res?.data?.message === "success") {
            localStorage.removeItem("client_secret");
            localStorage.removeItem("client_id");
            localStorage.setItem("token_gen", JSON.stringify(token));
            localStorage.setItem("c_code", res?.data?.data?.customercode);
            localStorage.setItem("email", res?.data?.data?.email);
            localStorage.setItem("mobile", res?.data?.data?.mobile);
            localStorage.setItem("name", res?.data?.data?.name);
            localStorage.setItem("logo", encrypt(logo));
            localStorage.setItem("clientsecret", response[0]?.merchantClientSecret);
            localStorage.setItem("clientid", response[0]?.merchantClientId);
            localStorage.setItem("uploadedSource", encrypt(uploadSource));
            navigate("/customer-portal");
        } else {
            setError(res?.message);
        }
        setLoader(false);
    };

    return (
        <section className="section signUpPage newStyle">
            {loader && <Loader />}
            <div className="signUpPage__main">
                {/* <AuthenticationSliderLeft response={responseData} /> */}
                <div className="signUpPage__right">
                    <div className="signUpPage__right__inside">
                        <div className="signUpPage__heading">
                            <figure>{logo ? <img src={logo} alt="logo" /> : <h1>{uploadSource}</h1>}</figure>
                            <h1>Great to have you back!</h1>
                        </div>
                        <div className="signUpPage__form">
                            <ValidatorForm className="signUpPage__formSection" ref={formRef} onSubmit={(e) => onSubmit(e)}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <TextValidator className="form-control" placeholder="Enter Email ID" name="email" maxLength="50" value={email} type="email" onChange={onChange} validators={["required"]} errorMessages={["Email address is required."]} />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group withIcon">
                                            <TextValidator placeholder="Enter Password" className="form-control" name="password" value={password} type={isRevealPwd ? "text" : "password"} onChange={onChange} validators={["required"]} errorMessages={["Password is required."]} onFocus={() => setPswdFocus(true)} onBlur={() => setPswdFocus(false)} />
                                            <span className="passwordToggle">{pswdFocus ? <img className="onFocus" title={isRevealPwd ? "Hide password" : "Show password"} src={isRevealPwd ? "/Images/eyeUnseenFocus.png" : "/Images/eyeSeenFocus.png"} alt="eye" onClick={() => setIsRevealPwd((prevState) => !prevState)} /> : <img className="onBlur" title={isRevealPwd ? "Hide password" : "Show password"} src={isRevealPwd ? "/Images/eyeUnseen.png" : "/Images/eyeSeen.png"} alt="eye" onClick={() => setIsRevealPwd((prevState) => !prevState)} />}</span>
                                            {error && <span className="textValidatorError">{error}</span>}
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <p className="text-end">
                                                <Link to={`/forgot-password`}>Forgot Password ?</Link>
                                            </p>

                                            {/* <p className="text-end">
                                                <Link to="/offer">Offer</Link>
                                            </p> */}
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <button type="submit" className="btn btn-primary btn-lg w-100">
                                                Sign in
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <p>
                                                New here? <Link to={"/signup"}>Create an account now</Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </ValidatorForm>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default SignIn;
